import { Widget } from "@typeform/embed-react";
import React, { useEffect, useState } from "react";
import { downloadFileFromAPI } from "../api";
import { CurrentStep, DescribeStepType } from "../types";
import { track, trackError, trackPage } from "../utils/telemetry";
import { SUPPORTED_FILE_EXTENSIONS } from "./UploadStep";

/* 
    When changing the example Excel files, update the below constants to reflect the new filenames and job IDs.
*/
export const EXAMPLE_FILENAMES = ['Q1 Sales Dashboard.xlsx', 'Mortgage Calculator.xlsx']

export const MORTGAGE_CALCULATOR_JOB_ID = 'f8bef8f2367aa58f1d5de27cbdf30f50'
export const Q1_SALES_DASHBOARD_JOB_ID = '0ab39016fc799944a4b4c7f5bc52c379'

export const EXAMPLE_JOB_IDS = [MORTGAGE_CALCULATOR_JOB_ID, Q1_SALES_DASHBOARD_JOB_ID]

export function DescribeStep(props: {
    currentStep: DescribeStepType,
    setCurrentStep: React.Dispatch<React.SetStateAction<CurrentStep>>,
}) {
    const currentStep = props.currentStep;
    const setCurrentStep = props.setCurrentStep;

    // Use useState to store the widget element and ensure it doesn't rerender
    const [widget, setWidget] = useState<JSX.Element | null>(null);

    useEffect(() => {
        trackPage('Describe Step')
    }, []);

    // Initialize the widget once when the component mounts
    useEffect(() => {

        const fileName = currentStep.uploadState.type === 'error' ? 'upload_error' : currentStep.uploadState.filename
        const date = new Date().toLocaleString('en-US', {
            hour12: false,
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        });

        const jobIdentifier = `${fileName} ${date}`
        const USER_EXCEL_FILE_TYPEFORM_ID = `jcUUwuCY?utm_source=app&utm_campaign=${jobIdentifier}`
        const EXAMPLE_TYPEFORM_ID = `jcUUwuCY?utm_source=app&utm_campaign=${fileName}`

        const isExampleExcel = EXAMPLE_FILENAMES.includes(fileName)

        setWidget(
            <Widget
                id={isExampleExcel ? EXAMPLE_TYPEFORM_ID : USER_EXCEL_FILE_TYPEFORM_ID}
                className="typeform"
                onSubmit={() => {
                    setCurrentStep((prevStep) => {
                        return {
                            ...prevStep,
                            typeformComplete: true
                        }
                    })
                    track('Typeform Completed', { 
                        typeform: isExampleExcel ? 'Example Typeform' : 'User Typeform',
                        typeform_job_id: jobIdentifier
                    })
                }}
            />
        );
    // We only want to run this effect once, so that the widget is only initialized once
    // and doesn't rerender and clear the users progress
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 

    // We have an effect that runs when the upload state is successful
    // which triggers the download to start
    useEffect(() => {
        const startDownload = async (jobID: string, fileName: string) => {      
            
            // If the user uploads a file that we cannot translate, then we 
            // don't want to start the download -- we just want to show the error
            let isSupported = false;
            for (let i = 0; i < SUPPORTED_FILE_EXTENSIONS.length; i++) {
                if (fileName.endsWith(SUPPORTED_FILE_EXTENSIONS[i])) {
                    isSupported = true;
                    break;
                }
            }

            if (!isSupported) {
                setCurrentStep((prevStep) => {
                    return {
                        ...prevStep,
                        downloadState: {
                            type: 'error'
                        }
                    }
                })
                return;
            }
            

            const downloadResult = await downloadFileFromAPI(jobID, false);
            if (downloadResult.type === 'error') {
                setCurrentStep((prevStep) => {
                    return {
                        ...prevStep,
                        downloadState: {
                            type: 'error'
                        }
                    }
                })
                trackError('Download File From API', { job_id: jobID })
            } else {
                setCurrentStep((prevStep) => {
                    return {
                        ...prevStep,
                        downloadState: {
                            type: 'success',
                            fullAutomationURL: downloadResult.fullAutomationURL,
                            previewAutomationURL: downloadResult.previewAutomationURL,
                            rawText: downloadResult.rawText,
                            downloadedPreview: false,
                            downloadedFullAutomation: false,
                        }
                    }
                })
                track('Download Successful', { job_id: jobID })
            }
        }

        if (currentStep.uploadState.type === 'success' && currentStep.downloadState.type === 'not-started') {
            // Set the download state to in-progress
            setCurrentStep((prevStep) => {
                return {
                    ...prevStep,
                    downloadState: {
                        type: 'in-progress'
                    }
                }
            })

            track('Upload Successful', { job_id: currentStep.uploadState.jobID })

            // And then start the download
            void startDownload(currentStep.uploadState.jobID, currentStep.uploadState.filename);
        }

        if (currentStep.uploadState.type === 'error') {
            trackError('Upload File', {})
        }

    }, [currentStep, setCurrentStep])


    // This next effect watches for when the typeform is completed, and 
    // the download is no longer in progress. We then advance to the download step
    useEffect(() => {
        if (currentStep.typeformComplete && currentStep.downloadState.type === 'success') {
            setCurrentStep({
                type: 'download',
                uploadState: currentStep.uploadState,
                downloadState: currentStep.downloadState
            })
        } else if (currentStep.typeformComplete && currentStep.downloadState.type === 'error') {
            setCurrentStep({
                type: 'download',
                uploadState: currentStep.uploadState,
                downloadState: currentStep.downloadState
            })
        }

    }, [currentStep, setCurrentStep])

    return <div className='step-area describe'>
        {props.currentStep.uploadState.type !== 'error' && widget}
        {props.currentStep.uploadState.type === 'error' && <div className='upload-error-message'>
            <p>
                Looks like you've pioneered into uncharted territory. We were unable to upload your file.
            </p>
            <p style={{marginTop: '1rem'}}>
                <a style={{textDecoration:'underline'}}href='mailto:founders@sagacollab.com?subject=Pyoneer upload error'>Send us an email</a> with your file attatched and we'll get it working.  
            </p> 
        </div>}
    </div>
}
