import React, { useEffect } from "react";
import { CurrentStep, DownloadState, LoadBalanceStepType } from "../types";
import { track, trackPage } from "../utils/telemetry";


export function LoadBalanceStep(props: {
    currentStep: LoadBalanceStepType,
    setCurrentStep: React.Dispatch<React.SetStateAction<CurrentStep>>,
    
}) {
    const [email, setEmail] = React.useState('');
    const [submitted, setSubmitted] = React.useState(false);

    const uploadState = props.currentStep.uploadState;

    useEffect(() => {
        trackPage('Load Balance Step')
    }, []);

    const onSubmit = async () => {
        // Submit email
        const fileName = uploadState.filename;
        // Track the filename
        track('email_submitted', {
            'email': email,
            'file_name': fileName,
        })
        setSubmitted(true);
    }


    return <div className='step-area load-balance'>
        <div>
            <h2>Sorry, Pyoneer is experiencing more traffic than normal and cannot process your file currently.</h2>
            <h2>Leave us your email, and you’ll get a Python script as soon as we’re back online.</h2>
            <div>
                <input type="email" name="email" placeholder="Enter your email" value={email} onChange={(e) => {setEmail(e.target.value); setSubmitted(false);}} />
                <button onClick={onSubmit}>Submit</button>
            </div>
            {submitted && <h2 style={{color: 'var(--dark-purple)'}}>Thank you! We’ll send an email to {email} with your Python script as soon as we’re back online.</h2>}
        </div>
    </div>
}