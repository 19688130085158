import { CurrentStep, StepName, StepStatus } from "./types"
import './TODOList.css'
import { classNames } from "./utils/classNames"

function StepDisplay(props: {
    stepDescription: string,
    status: StepStatus
}) {
    return <div className={`${props.status === 'active' && 'active-step'} steps-display`}>
        {
            props.status === 'error' ?
                <span className='step-status-error'>!</span>
            : <input type='checkbox' checked={props.status === 'complete'} readOnly/>
        }
        <p className={classNames(
            {'step-description-error': props.status === 'error'},
            {'step-description-complete': props.status === 'complete'}
        )}>{props.stepDescription}</p>
    </div>
}

export function TODOList(props: { currentStep: CurrentStep }) {
    if (props.currentStep.type === 'load-balance') {
        return null 
    }

    const stepDescriptions: Record<StepName, string> = {
        "upload": 'Upload an Excel File',
        "describe": 'Describe your Automation',
        "download": 'Download Preview',
        "payment-form": "Purchase Full Automation",
        "post-payment": "Download Automation Notebook",
    }

    let uploadStepStatus: StepStatus = 'incomplete'
    let describeStepStatus: StepStatus = 'incomplete'
    let downloadStepStatus: StepStatus = 'incomplete'
    let paymentFormStepStatus: StepStatus = 'incomplete'
    let postPaymentStepStatus: StepStatus = 'incomplete'

    // This logic is pretty nasty, but it's just to determine the status of each step
    // while taking into account errors. We can clean this up later.
    if (props.currentStep.type === 'upload') {
        uploadStepStatus = 'active'
    } else if (props.currentStep.type === 'describe') {
        if (props.currentStep.uploadState.type === 'error') {
            uploadStepStatus = 'error'
        } else {
            uploadStepStatus = 'complete'
        }

        if (props.currentStep.typeformComplete) {
            describeStepStatus = 'complete'
        } else {
            describeStepStatus = 'active'
        }
    } else if (props.currentStep.type === 'download') {
        if (props.currentStep.downloadState.type === 'error') {
            downloadStepStatus = 'error'
        } else if (props.currentStep.downloadState.type === 'success') {
            if (props.currentStep.downloadState.downloadedPreview) {
                downloadStepStatus = 'complete'
            } else {
                downloadStepStatus = 'active'
            }
        }

        uploadStepStatus = 'complete'
        describeStepStatus = 'complete'
    } else if (props.currentStep.type === 'payment-form') {
        uploadStepStatus = 'complete'
        describeStepStatus = 'complete'
        downloadStepStatus = 'complete'
        paymentFormStepStatus = 'active'
    } else if (props.currentStep.type === 'post-payment') {
        uploadStepStatus = 'complete'
        describeStepStatus = 'complete'
        downloadStepStatus = 'complete'
        paymentFormStepStatus = 'complete'

        const downloadState = props.currentStep.downloadState
        if (downloadState.type === 'success' && downloadState.downloadedFullAutomation) {
            postPaymentStepStatus = 'complete'
        } else {
            postPaymentStepStatus = 'active'
        }
    }

    return (
        <div className="steps-tracker">
            <StepDisplay
                key={'upload'}
                stepDescription={stepDescriptions['upload']}
                status={uploadStepStatus}
            />
            <StepDisplay
                key={'describe'}
                stepDescription={stepDescriptions['describe']}
                status={describeStepStatus}
            />
            <StepDisplay
                key={'download'}
                stepDescription={stepDescriptions['download']}
                status={downloadStepStatus}
            />
            <StepDisplay
                key={'payment-form'}
                stepDescription={stepDescriptions['payment-form']}
                status={paymentFormStepStatus}
            />
            <StepDisplay
                key={'post-payment'}
                stepDescription={stepDescriptions['post-payment']}
                status={postPaymentStepStatus}
            />
        </div>
    )
}
