
export const API_URL = 'https://ksy8bl4et9.execute-api.us-east-1.amazonaws.com/Prod'
export type UploadResult = {
    type: 'success',
    jobID: string,
    filename: string
} | {
    type: 'error',
    filename: string
}

export type DownloadResult = {
    type: 'success',
    fullAutomationURL: string,
    previewAutomationURL: string,
    rawText: string
} | {
    type: 'error'
}

export const uploadFileToS3 = async (file: File): Promise<{type: 'error'} | {type: 'success', upload_id: string}> => {
    // Generate a 20 character alphanumic random string. Note that the API
    // requires that it's exactly 20 characters long, and only alphanumeric
    const uploadID = (Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)).substring(0, 20)

    // Then, call the /get_upload_url?upload_id=randomString endpoint to get the upload URL
    const response = await fetch(`${API_URL}/get_upload_url?upload_id=${uploadID}`, {
        method: 'POST',
    });

    if (!response.ok) {
        return {
            type: 'error'
        }
    }

    const result = await response.json();
    const uploadURL = result['upload_url'];

    if (!uploadURL) {
        return {
            type: 'error'
        }
    }
    
    // Then, upload the file to the upload URL
    const uploadResponse = await fetch(uploadURL, {
        method: 'PUT',
        body: file,
        headers: {
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }
    });

    if (!uploadResponse.ok) {
        return {
            type: 'error'
        }
    }

    // Then, return success and the upload_id
    return {
        type: 'success',
        upload_id: uploadID
    }
}

/**
 * Uploads a file to the server.
 * Returns the jobID if the upload was successful, or null if there was an error.
 */
export const uploadFileToAPI = async (file: File): Promise<UploadResult> => {

    const uploadResponse = await uploadFileToS3(file);
    if (uploadResponse.type === 'error') {
        return {
            type: 'error',
            filename: file.name
        }
    }
    const fileName = file.name;
    const uploadID = uploadResponse.upload_id;

    const payload = {
        file_name: fileName,
        upload_id: uploadID
    };

    try {
        console.log("Preparing to fetch...")
        const response = await fetch(
            `${API_URL}/upload`, { 
                method: 'POST', 
                headers: {
                    "Content-Type": "application/json"
                }, 
                body: JSON.stringify(payload) 
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        const jobID = result['job_id'];
        return {
            type: 'success',
            jobID: jobID,
            filename: fileName
        }
    } catch (e) {
        return {
            type: 'error',
            filename: fileName
        }
    }
}

function base64ToBlob(base64: string, contentType: string): Blob {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
}

function decodeBase64ToString(base64String: string): string {
    // Decode base64 to byte array
    const byteArray = atob(base64String);
  
    // Convert byte array to string
    let originalString = "";
    for (let i = 0; i < byteArray.length; i++) {
      originalString += String.fromCharCode(byteArray.charCodeAt(i));
    }
  
    return originalString;
  }

export const downloadDataURL = (dataURL: string, filename: string) => {
    const a = document.createElement('a');
    a.href = dataURL;
    a.download = filename;
    a.click();
}

export const downloadXLSXFromAPI = async (jobID: string): Promise<{
    type: 'success',
    url: string
} | {type: 'error'}> => {

    try {
        const response = await fetch(`${API_URL}/download?job_id=${jobID}&xlsx_only=${true}`);

        if (response.status === 200) {
            const result = await response.json();
            console.log(result)

            const xlsxData = result['data']

            // The data is base64 encoded, decode it
            const blob = base64ToBlob(xlsxData, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

            // Create a blob for the xlsx file
            const url = window.URL.createObjectURL(blob);

            return {
                type: 'success',
                url: url
            }
        } else {
            return {
                type: 'error'
            }
        }
    } catch (e) {
        console.log(e)
        return {
            type: 'error'
        }
    }
}

/**
 * Polls the server to check if the file is ready for download.
 * When the file is ready for download (or there is an error),
 * the onDownload callback is called with the URL of the file.
 * If the server sends a 202 status code, the file is still processing,
 * so we try again in 1 second.
 */
export const downloadFileFromAPI = async (jobID: string, xlsxOnly: boolean): Promise<DownloadResult> => {

    // Since we're waiting for the file to be processed, we'll poll the server
    // for the file every second for 5 minutes (300 seconds)
    for (let i = 0; i < 300; i++) {
        try {
            const response = await fetch(`${API_URL}/download?job_id=${jobID}&xlsx_only=${false}`);

            if (response.status === 202) {
                // Do nothing, the file is still processing
            } else if (response.status === 200) {
                const result = await response.json();

                const fullAutomationZip = result['full_automation']
                const fullAutomationBlob = base64ToBlob(fullAutomationZip, 'application/zip');
                const fullAutomationURL = window.URL.createObjectURL(fullAutomationBlob);

                const previewAutomationZip = result['preview_automation'];
                const previewAutomationBlob = base64ToBlob(previewAutomationZip, 'application/zip');
                const previewAutomationURL = window.URL.createObjectURL(previewAutomationBlob);

                const rawText = result['raw_text'] // This is a base64 encoded string. We need to decode it to utf-8, diff than above
                const rawTextString = decodeBase64ToString(rawText);
                // TODO: we need to decode the raw text to a string
                return {
                    type: 'success',
                    fullAutomationURL: fullAutomationURL,
                    previewAutomationURL: previewAutomationURL,
                    rawText: rawTextString
                }
            } else {
                return {
                    type: 'error'
                }
            }
        } catch (e) {
            console.log(e)
            return {
                type: 'error'
            }
        }

        // Wait for 1 second before polling again
        await new Promise((resolve) => {
            setTimeout(resolve, 1000);
        });
    }
    
    return {
        type: 'error'
    }
}
