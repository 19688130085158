import React, { useEffect } from "react";
import { DownloadState } from "./types";

const DOWNLOAD_LOADING_MESSAGES = [
    'Uploading Excel file',
    'Checking Excel file',
    'Locating tablular data',
    'Sorting formulas in order',
    'Translating formulas to Python',
    'Creating a notebook'
];
const DOWNLOAD_COMPLETED_MESSAGE = 'Complete the form to download your Python automation.';
const DOWNLOAD_ERROR_MESSAGE = 'There was an error processing your request.';

const getMessage = (downloadState: DownloadState, messageIndex: number) => {
    if (downloadState.type === 'error') {
        return DOWNLOAD_ERROR_MESSAGE;
    } else if (downloadState.type === 'success') {
        return DOWNLOAD_COMPLETED_MESSAGE;
    } else {
        return DOWNLOAD_LOADING_MESSAGES[messageIndex % DOWNLOAD_LOADING_MESSAGES.length];
    }
}

function LoadingBar(props: {
    downloadState: DownloadState
}) {
    const [messageIndex, setMessageIndex] = React.useState(0);
    
    // Update message index every 10 seconds, making sure to stop 
    // at the last message
    useEffect(() => {
        const interval = setInterval(() => {
            setMessageIndex((prevIndex) => Math.min(prevIndex + 1, DOWNLOAD_LOADING_MESSAGES.length - 1))
        }, 10000);
        return () => clearInterval(interval);
    }, [])

    const message = getMessage(props.downloadState, messageIndex);
    const isLoading = props.downloadState.type === 'not-started' || props.downloadState.type === 'in-progress';
    return (
        <div className="loading-bar">
            <div>{message}</div>
            {true && <progress className="linear-progress"/>}
        </div>
    );
}

export { LoadingBar };