import React, { useEffect, useState } from "react";
import { DownloadIcon } from "../Icons/DownloadIcon";
import { UploadResult, downloadDataURL, downloadXLSXFromAPI, uploadFileToAPI } from "../api";
import { CurrentStep } from "../types";
import { track, trackButtonClick, trackError, trackPage } from "../utils/telemetry";
import { EXAMPLE_FILENAMES, EXAMPLE_JOB_IDS, MORTGAGE_CALCULATOR_JOB_ID, Q1_SALES_DASHBOARD_JOB_ID } from "./DescribeStep";

const UPLOADABLE_FILE_EXTENSIONS = [
    '.xlsx', 
    '.xls',
    '.xlsm',
    '.xlsb',
]

export const SUPPORTED_FILE_EXTENSIONS = [
    '.xlsx',
    '.xls',
]

function ExampleButton(props: { 
    filename: string, 
    job_id: string, 
    updateStepFromUploadResult: (uploadResult: UploadResult) => void 
}) {
    return <div className='example-button'>
        <button
            style={{
                borderRadius: '5px 0 0 5px'
            }}
            onClick={() => {
                props.updateStepFromUploadResult({
                    'type': 'success',
                    'jobID': props.job_id,
                    'filename': props.filename
                })
                trackButtonClick('Use Example', { filename: props.filename })
            }}
            title='Try out Sample Excel File'
        >
            {props.filename}
        </button>
        <button
            style={{
                borderRadius: '0 5px 5px 0',
            }}
            onClick={async () => {
                const downloadResult = await downloadXLSXFromAPI(props.job_id);
                if (downloadResult.type === 'error') {
                    console.error('Error downloading file');
                    trackError('Download Example XLSX', { filename: props.filename })
                    return
                }
                downloadDataURL(downloadResult.url, props.filename)
                trackButtonClick('Download Example XLSX', { filename: props.filename })
            }}
            title='Download Sample Excel File'
            className='example-download-button'
        >
            <DownloadIcon width="15px" height="15px" strokeWidth="5" />
        </button>
    </div>
}

export function UploadStep(props: {
    setCurrentStep: React.Dispatch<React.SetStateAction<CurrentStep>>,
}) {
    const inputFileRef = React.useRef<HTMLInputElement>(null)
    const [dragging, setDragging] = useState(false);

    useEffect(() => {
        trackPage('Upload Step')
    }, []);

    const updateStepFromUploadResult = (uploadResult: UploadResult) => {
        const isExampleExcel = uploadResult.type === 'success' && EXAMPLE_JOB_IDS.includes(uploadResult.jobID)
        if (isExampleExcel) {
            props.setCurrentStep({
                type: 'describe',
                uploadState: uploadResult,
                typeformComplete: false,
                downloadState: {
                    type: 'not-started',
                }
            });
        } else {
            props.setCurrentStep({
                type: 'load-balance',
                uploadState: uploadResult
            });
        }   
    }

    const onFileChange = async (file: File) => {
        const isExampleExcel = EXAMPLE_FILENAMES.includes(file.name);
        if (isExampleExcel) {

            props.setCurrentStep({
                type: 'describe',
                uploadState: {
                    'type': 'in-progress',
                    'filename': file.name
                },
                typeformComplete: false,
                downloadState: {
                    type: 'not-started',
                }
            });
        } else {
            props.setCurrentStep({
                type: 'load-balance',
                uploadState: {
                    'type': 'in-progress',
                    'filename': file.name
                }
            });
        }
            
        track('Upload File', { filename: file.name })
        const uploadResult = await uploadFileToAPI(file);
        updateStepFromUploadResult(uploadResult);
    }

    const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);

        const validFileType = UPLOADABLE_FILE_EXTENSIONS.some(ext => e.dataTransfer.files[0].name.endsWith(ext));

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0 && validFileType) {
            const file = e.dataTransfer.files[0];
            await onFileChange(file);
            e.dataTransfer.clearData();
        }
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (!dragging) {
            setDragging(true);
        }
    };

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
    };

    const onUploadButtonClick = () => {
        if (inputFileRef.current) {
            inputFileRef.current.click()
        }
    }

    return (
        <div 
            className={`step-area ${dragging ? 'dragging' : ''}`} 
            onDrop={handleDrop} 
            onDragOver={handleDragOver} 
            onDragLeave={handleDragLeave}
        >
            <input
                type='file'
                accept={UPLOADABLE_FILE_EXTENSIONS.join(',')}
                ref={inputFileRef}
                onChange={e => {
                    const file = e.target.files && e.target.files[0];
                    if (file) {
                        onFileChange(file);
                    }
                }}
                style={{ display: 'none' }}
            />
            <button
                onClick={onUploadButtonClick}
                className='upload-button'
            >
                Upload your Excel File
                <DownloadIcon />
            </button>
            <div className="example-buttons-container">
                <p> <b>Or select an example:</b></p>
                <div className="example-buttons">
                    <ExampleButton
                        filename={'Mortgage Calculator.xlsx'}
                        job_id={MORTGAGE_CALCULATOR_JOB_ID}
                        updateStepFromUploadResult={updateStepFromUploadResult}
                    />
                    <ExampleButton
                        filename={'Q1 Sales Dashboard.xlsx'}
                        job_id={Q1_SALES_DASHBOARD_JOB_ID}
                        updateStepFromUploadResult={updateStepFromUploadResult}
                    />
                </div>
            </div>
        </div>
    );
}
