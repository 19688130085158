import React, { MouseEventHandler, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import SyntaxHighlighter from 'react-syntax-highlighter';
import a11yLight from "react-syntax-highlighter/dist/esm/styles/hljs/a11y-light";
import { API_URL, downloadDataURL, downloadFileFromAPI } from "../api";
import { CurrentStep, DownloadState, PostPaymentStepType } from "../types";
import { getCodePreview } from "./DownloadStep";
import { trackButtonClick, trackPage } from "../utils/telemetry";


export function PostPaymentStep(props: {
    currentStep: CurrentStep,
    setCurrentStep: React.Dispatch<React.SetStateAction<CurrentStep>>,
}) {
    let downloadState: DownloadState | null = null;
    if (props.currentStep.type === 'post-payment') {
        downloadState = props.currentStep.downloadState;
    }

    const [status, setStatus] = useState(null);
    const [customerEmail, setCustomerEmail] = useState('');
    const [jobID, setJobID] = useState('');

    useEffect(() => {
        trackPage('Post Payment Step')
    }, []);
  
    useEffect(() => {
        const fetchSessionStatus = async () => {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const sessionId = urlParams.get('session_id');
        
            const response = await fetch(API_URL + `/session-status?session_id=${sessionId}`);
            const data = await response.json();

            setStatus(data.status);
            setCustomerEmail(data.customer_email);

            // Reidentify the user with their email
            try {
                (window as any).analytics.identify({email: data.customer_email})
            } catch (e) {
                console.error('Error identifying user', e)
            }
        }
        void fetchSessionStatus()
    }, []);

    useEffect(() => {
        // Download the results
        const fetchDownload = async () => {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const job_id = urlParams.get('job_id');

            if (job_id === null) {
                return;
            }

            setJobID(job_id);

            const result = await downloadFileFromAPI(job_id, false);
            const downloadState: DownloadState = {
                ...result,
                downloadedPreview: false,
            } as DownloadState; // TODO: this is bugged
            props.setCurrentStep((prevStep) => {

                return {
                    'type': 'post-payment',
                    uploadState: {
                        'type': 'success',
                        'jobID': job_id,
                        'filename': 'example.xlsx' // todo: get the filename from the API
                    },
                    downloadState: downloadState,
                }
            })
        }

        fetchDownload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClickDownloadFullAutomation: MouseEventHandler = (e) => {
        // Stop propagation, so in case the user clicks a button, it 
        // doesn't trigger the onClick event of the parent div and download
        // twice
        e.stopPropagation()

        if (downloadState === null || downloadState.type !== 'success') return

        // Mark downloadedFullAutomation as True
        const currentState = props.currentStep as PostPaymentStepType
        props.setCurrentStep({
            type: 'post-payment',
            downloadState: {
                ...downloadState,
                downloadedFullAutomation: true
            },
            uploadState: currentState.uploadState
        })

        const fullAutomationURL = downloadState.fullAutomationURL;
        downloadDataURL(fullAutomationURL, 'Automation.zip');

        trackButtonClick('Download Full Automation', { 
            job_id: jobID, 
            customer_email: customerEmail
        })
    }

    if (status === 'open') {
      return (
        <Navigate to="/checkout" />
      )
    }
  
    if (status === 'complete') {
      return (
        <div className='step-area download'>
            <SyntaxHighlighter
                onClick={onClickDownloadFullAutomation}
                language='python'
                style={a11yLight}
                showLineNumbers
                customStyle={{
                    padding: '25px 16px',
                    backgroundImage: 'linear-gradient(transparent, var(--medium-purple))',
                    border: '1px solid gray',
                    borderRadius: '4px',
                    cursor: downloadState?.type !== 'success' ? 'auto' : 'pointer',
                    maxWidth: window.innerWidth < 600 ? '90%' : '475px',
                    maxHeight: '309px'
                }}
            >
                {getCodePreview(downloadState)}
            </SyntaxHighlighter>
            {(downloadState === null || downloadState.type !== 'success') &&
                <p className="conversion-error-message">Looks like automating your file isn't supported yet. We’ll reach out when it is. </p>
            }
            {downloadState !== null && downloadState.type === 'success' &&
                <div>
                    <button
                        onClick={onClickDownloadFullAutomation}
                    >
                        Download Full Automation
                    </button>
                </div>
            }
        </div>
      )
    }
  
    return null;
}