import React, { useCallback, useEffect } from "react";
import { CurrentStep, PaymentFormStepType, UploadStateSuccess } from "../types";
import './PaymentFormStep.css';

import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { API_URL } from "../api";
import { trackPage } from "../utils/telemetry";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe("pk_live_51PHb29Ea4v1Zf8aFatOjj2mSbxubeH2n9ws4DNnNkvLZ3y5EIVIwjEOYrLQ9ihQ6pQA0xdcBaF3KtwFuNoK7FYbT007J1EPWmm");

const CheckoutForm = (props: {uploadState: UploadStateSuccess}) => {
  const job_id = props.uploadState.jobID;
  const fetchClientSecret = useCallback(() => {
    // Create a Checkout Session
    const currentUrl = window.location.origin;
    return fetch(API_URL + "/create-checkout-session?domain=" + currentUrl + '&job_id=' + job_id, {
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => data.clientSecret);
  // Disable the linter warning about missing dependencies
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = {
    fetchClientSecret,
  };

  return (
    <div id="checkout" className='checkout-form'>
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  )
}


export function PaymentFormStep(props: {
    currentStep: PaymentFormStepType,
    setCurrentStep: React.Dispatch<React.SetStateAction<CurrentStep>>,
}) {
    useEffect(() => {
      trackPage('Payment Form Step')
  }, []);
    
    return <div className='step-area step-area-checkout-form'>
        <CheckoutForm
          // This cast is safe, as you can only get to this step if the upload was successful
          // but ideally we would update the types here
          uploadState={props.currentStep.uploadState as UploadStateSuccess}
        />
    </div>
}