import React from 'react';

export const PyoneerIcon = () => {
    return <svg width="40" height="40" viewBox="0 0 600 622" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_179_20)">
            <path d="M274.831 353.492L274.613 352.393L273.497 352.291L30.2237 330.02C1.56662 327.397 -5.58705 288.789 20.2277 276.073L581.782 -0.549662C588.669 -3.94204 596.168 2.8104 593.514 10.0139L377.106 597.407C367.157 624.41 328.013 621.331 322.409 593.105L274.831 353.492Z" fill="#6246EA" stroke="white" strokeWidth="3"/>
        </g>
        <defs>
            <clipPath id="clip0_179_20">
                <rect width="600" height="622" fill="white"/>
            </clipPath>
        </defs>
    </svg>
}
