import React, { MouseEventHandler, useEffect } from "react";
import SyntaxHighlighter from 'react-syntax-highlighter';
import a11yLight from "react-syntax-highlighter/dist/esm/styles/hljs/a11y-light";
import { downloadDataURL } from "../api";
import { CurrentStep, DownloadState, DownloadStepType } from "../types";
import { EXAMPLE_JOB_IDS } from "./DescribeStep";
import { trackButtonClick, trackPage } from "../utils/telemetry";

export const getCodePreview = (downloadState: DownloadState | null) => {
    if (downloadState?.type !== 'success') {
        return `AutomationError \nTraceback (most recent call last):  File "C:/Python27/van.py", line 1, in\n     python_automation = automate_xlsx("excel_file.xlsx")`
    } else {
        // Return only the first 10 lines of the code
        return downloadState.rawText.split('\n').slice(0, 20).join('\n')
    }
}

export function DownloadStep(props: {
    currentStep: DownloadStepType,
    setCurrentStep: React.Dispatch<React.SetStateAction<CurrentStep>>,
    
}) {
    const uploadState = props.currentStep.uploadState;
    const downloadState = props.currentStep.downloadState;

    useEffect(() => {
        trackPage('Download Step')
    }, []);

    const onClickDownloadPreview: MouseEventHandler = (e) => {
        // Stop propagation, so in case the user clicks a button, it 
        // doesn't trigger the onClick event of the parent div and download
        // twice
        e.stopPropagation()

        if (downloadState.type !== 'success') return

        const previewAutomationURL = downloadState.previewAutomationURL;
        downloadDataURL(previewAutomationURL, 'PreviewAutomation.zip');
        
        props.setCurrentStep({
            ...props.currentStep,
            downloadState: {
                ...downloadState,
                downloadedPreview: true
            }
        })

        trackButtonClick('Download Preview', { job_id: props.currentStep.uploadState.type === 'success' ? props.currentStep.uploadState.jobID : 'unknown'})
    }

    const onClickPurchaseAutomation: MouseEventHandler = (e) => {
        e.stopPropagation()

        props.setCurrentStep({
            type: 'payment-form',
            uploadState: props.currentStep.uploadState,
            downloadState: props.currentStep.downloadState,
        })

        trackButtonClick('Purchase Full Automation', { job_id: props.currentStep.uploadState.type === 'success' ? props.currentStep.uploadState.jobID : 'unknown'})
    }

    const onClickDownloadExampleAutomation: MouseEventHandler = (e) => {
        e.stopPropagation()

        if (downloadState.type !== 'success') return

        const fullAutomationURL = downloadState.fullAutomationURL;
        downloadDataURL(fullAutomationURL, 'Automation.zip');

        trackButtonClick('Download Example Automation', { job_id: props.currentStep.uploadState.type === 'success' ? props.currentStep.uploadState.jobID : 'unknown'})
    }

    const onClickUploadNewFile: MouseEventHandler = (e) => {
        e.stopPropagation()

        props.setCurrentStep({
            type: 'upload',
        })

        trackButtonClick('Upload New File', { job_id: props.currentStep.uploadState.type === 'success' ? props.currentStep.uploadState.jobID : 'unknown'})
    }

    const isExampleExcel = uploadState.type === 'success' && EXAMPLE_JOB_IDS.includes(uploadState.jobID)

    return <div className='step-area download' onClick={onClickDownloadPreview}>
        {downloadState.type === 'success' &&
            <SyntaxHighlighter
                language='python'
                style={a11yLight}
                showLineNumbers
                customStyle={{
                    padding: '25px 16px',
                    backgroundImage: 'linear-gradient(transparent, var(--medium-purple))',
                    border: '1px solid gray',
                    borderRadius: '4px',
                    cursor: downloadState?.type !== 'success' ? 'auto' : 'pointer',
                    maxWidth: window.innerWidth < 600 ? '90%' : '475px',
                    maxHeight: '309px'
                }}
            >
                {getCodePreview(props.currentStep.downloadState)}
            </SyntaxHighlighter>
        }
        {downloadState.type === 'error' &&
            <div className='upload-error-message'>
                <p>
                    Looks like you've pioneered into uncharted territory. 
                </p>
                <p style={{marginTop: '1rem'}}>
                    We'll send you an email when your file is supported.
                </p>
                <p style={{marginTop: '1rem'}}>
                    Try using one of the sample files or uploading a different file.
                </p> 
                <div style={{width: "100%", textAlign: 'center'}}>
                    <button
                        onClick={(e) => {
                            onClickUploadNewFile(e)
                            trackButtonClick('Upload New File', { 
                                job_id: props.currentStep.uploadState.type === 'success' ? props.currentStep.uploadState.jobID : 'unknown',
                                location: 'download step error message'
                            })
                        }}
                    >
                        Upload new file
                    </button>
                </div>
            </div>
        }
        {downloadState.type === 'success' && isExampleExcel &&
            <div className="download-buttons">
                <button
                    onClick={onClickDownloadExampleAutomation}
                >
                    Download Automation
                </button>
                <button
                    onClick={onClickUploadNewFile}
                >
                    Upload new file
                </button>
            </div>
        }
        {downloadState.type === 'success' && !isExampleExcel &&
            <div className="download-buttons">
                <button
                    onClick={onClickDownloadPreview}
                >
                    Download Preview
                </button>
                <button
                    onClick={onClickPurchaseAutomation}
                >
                    Purchase Full Automation ($100)
                </button>
            </div>
        }
    </div>
}