
// Function to set a cookie
function setCookie(name: string, value: string) {
    const newCookie = `${name}=${value}; path=/; domain=.pyoneer.ai; Secure; SameSite=None`;
    document.cookie = newCookie;
}

// Function to get a cookie
function getCookie(name: string): string | undefined {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return undefined;
}

function generateNewAnonymousID(): string {
    // Generate a random string
    const randomString = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    return randomString;
}

export const getAnonymousId = () => {
    let anonymousId = getCookie('anonymous_id');
    if (anonymousId === undefined) {
        const newAnonymousId = generateNewAnonymousID();
        setCookie('anonymous_id', newAnonymousId);
        return newAnonymousId
    } 
    return anonymousId;
}

export const trackButtonClick = (buttonName: string, params: Record<string, string>) => {
    const logName = `Clicked ${buttonName}`
    track(logName, params)
};

export const trackError = (failedProcess: string, params: Record<string, string>) => {
    const errorName = `${failedProcess} failed`
    track(errorName, params)
}

export const track = (eventName: string, params: Record<string, string | null>) => {
    try {
        (window as any).analytics.track(eventName, params)
    } catch (e) {
        console.error(`Error tracking event: ${eventName}`, e)
    }
}

export const trackPage = (pageName: string, params?: Record<string, string>) => {
    try {
        (window as any).analytics.page(pageName, params)
    } catch (e) {
        console.error(`Error tracking page: ${pageName}`, e)
    }
}
