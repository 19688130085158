import React, { useEffect } from 'react';
import './App.css';
import { PyoneerIcon } from './Icons/PyoneerIcon';
import { LoadingBar } from './LoadingBar';
import { Step } from './Step';
import { TODOList } from './TODOList';
import './globals.css';
import { CurrentStep } from './types';

import {
    Route,
    BrowserRouter as Router,
    Routes
} from "react-router-dom";
import { PostPaymentStep } from './Steps/PostPaymentStep';
import { getAnonymousId, trackButtonClick } from './utils/telemetry';
import { classNames } from './utils/classNames';

/**
 * App contains the steps tracker 
 * and the actual content for the step. 
 */
function App() {
    const [currentStep, setCurrentStep] = React.useState<CurrentStep>({
        'type': 'upload',
    })

    useEffect(() => {
        try {
            const anonymousId = getAnonymousId();
            (window as any).analytics.identify(anonymousId)
        } catch (e) {
            console.error('Error identifying user', e)
        }
    }, []);
    

    // We stop the user from closing the page if they are on any step other than the upload step
    // and they have not downloaded the file.
    useEffect(() => {
        if (currentStep.type === 'upload') {
            window.onbeforeunload = null
        } else if (currentStep.type === 'download' && currentStep.downloadState.type === 'success' && currentStep.downloadState.downloadedPreview) {
            window.onbeforeunload = null
        } else if (currentStep.type === 'payment-form') {
            // We don't want to stop the user from leaving the page if they are on the payment form
            // since they are redirected to a different page at the end of this
            window.onbeforeunload = null
        } else {
            window.onbeforeunload = () => true
        }

        return () => {
            window.onbeforeunload = null
        }
    }, [currentStep])



    return (
        <div className="App">
            <div className='header'>
                <div className='logo-container'>
                    <PyoneerIcon />
                    <h1>Pyoneer <i className='text-highlight'>Alpha Preview</i></h1>
                </div>
                <div>
                    <a 
                        className={classNames('translucent-button', 'hide_on_small_screen')}
                        style={{marginRight: '2rem'}} 
                        href='mailto:founders@sagacollab.com'
                        onClick={() => trackButtonClick('Contact', {})}
                    >
                        Contact
                    </a>
                    <a
                        className='translucent-button'
                        href='/pyoneer-alpha-launch.pdf'
                        target='_blank'
                        rel='noreferrer'
                        role='button'
                        onClick={() => trackButtonClick('Join the alpha program', {})}
                    >
                        Join the alpha program
                    </a>
                </div>
            </div>
            <div className='title-card'>
                <h1 className='main-title'>Upload your Excel File. Download a Python Script</h1>
            </div>
            
            <div className='main-area'>
                <Router>
                    <Routes>
                        <Route path="/" element={<Step currentStep={currentStep} setCurrentStep={setCurrentStep} />} />
                        <Route path="/return" element={<PostPaymentStep currentStep={currentStep} setCurrentStep={setCurrentStep}/>} />
                    </Routes>
                </Router>
                <TODOList currentStep={currentStep} />
            </div>

            
            {currentStep.type === 'describe' && <LoadingBar downloadState={currentStep.downloadState} />}
        </div>
    )
}

export default App;
