import React from "react";
import { DescribeStep, DownloadStep, UploadStep } from "./Steps";
import { CurrentStep } from "./types";
import { PaymentFormStep } from "./Steps/PaymentFormStep";
import { PostPaymentStep } from "./Steps/PostPaymentStep";
import { LoadBalanceStep } from "./Steps/LoadBalanceStep";

export const Step = (props: {
    currentStep: CurrentStep,
    setCurrentStep: React.Dispatch<React.SetStateAction<CurrentStep>>,
}): JSX.Element => {
    const currentStep = props.currentStep;
    

    if (currentStep.type === 'upload') {
        return (
            <UploadStep setCurrentStep={props.setCurrentStep}/>
        )
    } else if (currentStep.type === 'load-balance') {
        return (
            <LoadBalanceStep currentStep={currentStep} setCurrentStep={props.setCurrentStep}/>
        )
    } else if (currentStep.type === 'describe') {
        return (
            <DescribeStep currentStep={currentStep} setCurrentStep={props.setCurrentStep}/>
        )
    } else if (currentStep.type === 'download') {
        return (
            <DownloadStep currentStep={currentStep} setCurrentStep={props.setCurrentStep}/>
        )
    } else if (currentStep.type === 'payment-form') {
        return (
            <PaymentFormStep currentStep={currentStep} setCurrentStep={props.setCurrentStep}/>
        )
    } else {
        return (
            <PostPaymentStep currentStep={currentStep} setCurrentStep={props.setCurrentStep}/>
        )
    }
}